var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'type_exercise',title: _vm.$t('type_of_exercise')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_type_exercise"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('code') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'type_exercise_code',
                                    name: 'type_exercise[code]',
                                    style: 'width: 200px',
                                    value: _vm.ConfigTypeExercise.data.code,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'type_exercise_name',
                                    name: 'type_exercise[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.ConfigTypeExercise.data.name,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                },"datainput":{
                                    id: 'type_exercise_description',
                                    name: 'type_exercise[description]',
                                    value: _vm.ConfigTypeExercise.data.description
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{ref:"RefTypeExercise",attrs:{"dataBlock":{
                                    type: 'radio',
                                    id: 'type_exercise_type',
                                    label: _vm.$t('type') + '*',
                                    title: _vm.$t('type'),
                                    text: _vm.$t('select_type') + ':',
                                    name: 'type_exercise[type][]',
                                    id_value: 'value',
                                    name_label: 'text',
                                    child_key: 'children',
                                    required: true,
                                    checkparentdata: false,
                                    style: '',
                                    label_style: 'width: 148px',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                },"selected_input":_vm.ConfigTypeExercise.data.type ? _vm.ConfigTypeExercise.data.type : [],"items":_vm.getFeeds.feed_type},on:{"accept":function($event){return _vm.changeType()}}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.ConfigTypeExercise.data.type[0])[1]))])])]],2),_c('div',{staticClass:"gh-row-content striped_white",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('name'), style: 'width: 130px', id: 'label_type_exercise_statement'},"datainput":{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_statement', value: 1, name: 'type_exercise[config][statement][]'}}}),_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_statement'},"datainput":{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_required_statement', value: 2, name: 'type_exercise[config][statement][]'}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigResponsible",attrs:{"datalabel":{text: _vm.$t('responsible'), style: 'width: 130px', id: 'label_type_exercise_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][responsible][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.responsible && _vm.ConfigTypeExercise.data.config.responsible[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigResponsibleRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_responsible', value: 2, name: 'type_exercise[config][responsible][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.responsible && _vm.ConfigTypeExercise.data.config.responsible[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigInformation",attrs:{"datalabel":{text: _vm.$t('information'), style: 'width: 130px', id: 'label_type_exercise_information'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_information', value: 1, name: 'type_exercise[config][information][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.information && _vm.ConfigTypeExercise.data.config.information[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigInformation', 'GhCheckConfigInformationRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigInformationRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_information'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_information', value: 2, name: 'type_exercise[config][information][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.information && _vm.ConfigTypeExercise.data.config.information[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigInformation', 'GhCheckConfigInformationRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigScoreLegend",attrs:{"datalabel":{text: _vm.$t('score_legend'), style: 'width: 130px', id: 'label_type_exercise_score_legend'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_score_legend', value: 1, name: 'type_exercise[config][score_legend][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.score_legend && _vm.ConfigTypeExercise.data.config.score_legend[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigScoreLegend', 'GhCheckConfigScoreLegendRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigScoreLegendRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_score_legend'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_score_legend', value: 2, name: 'type_exercise[config][score_legend][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.score_legend && _vm.ConfigTypeExercise.data.config.score_legend[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigScoreLegend', 'GhCheckConfigScoreLegendRequired', true)}}})],1)])])]),_c('div',{staticClass:"gh-row-content striped",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigExamples",attrs:{"datalabel":{text: _vm.$t('examples'), style: 'width: 130px', id: 'label_type_exercise_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_examples', value: 1, name: 'type_exercise[config][examples][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.examples && _vm.ConfigTypeExercise.data.config.examples[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigExamplesRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_examples', value: 2, name: 'type_exercise[config][examples][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.examples && _vm.ConfigTypeExercise.data.config.examples[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigBibliography",attrs:{"datalabel":{text: _vm.$t('bibliography'), style: 'width: 130px', id: 'label_type_exercise_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_bibliography', value: 1, name: 'type_exercise[config][bibliography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.bibliography && _vm.ConfigTypeExercise.data.config.bibliography[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigBibliographyRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_bibliography', value: 2, name: 'type_exercise[config][bibliography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.bibliography && _vm.ConfigTypeExercise.data.config.bibliography[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigSupportMaterial",attrs:{"datalabel":{text: _vm.$t('materials'), style: 'width: 130px', id: 'label_type_exercise_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_support_material', value: 1, name: 'type_exercise[config][support_material][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.support_material && _vm.ConfigTypeExercise.data.config.support_material[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigSupportMaterialRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_support_material', value: 2, name: 'type_exercise[config][support_material][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.support_material && _vm.ConfigTypeExercise.data.config.support_material[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigGoodPractices",attrs:{"datalabel":{text: _vm.$t('good_practices'), style: 'width: 130px', id: 'label_type_exercise_good_practices'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_good_practices', value: 1, name: 'type_exercise[config][good_practices][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.good_practices && _vm.ConfigTypeExercise.data.config.good_practices[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigGoodPractices', 'GhCheckConfigGoodPracticesRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigGoodPracticesRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_good_practices'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_good_practices', value: 2, name: 'type_exercise[config][good_practices][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.good_practices && _vm.ConfigTypeExercise.data.config.good_practices[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigGoodPractices', 'GhCheckConfigGoodPracticesRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigTheory",attrs:{"datalabel":{text: _vm.$t('theory'), style: 'width: 130px', id: 'label_type_exercise_theory'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_theory', value: 1, name: 'type_exercise[config][theory][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.theory && _vm.ConfigTypeExercise.data.config.theory[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigTheory', 'GhCheckConfigTheoryRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigTheoryRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_theory'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_theory', value: 2, name: 'type_exercise[config][theory][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.theory && _vm.ConfigTypeExercise.data.config.theory[1] === 2,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigTheory', 'GhCheckConfigTheoryRequired', true)}}})],1)])])]),_c('div',{staticClass:"gh-row-content striped_white",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigAutoevaluation",attrs:{"datalabel":{text: _vm.$t('exercise_autoevaluation'), style: 'width: 294px', id: 'label_type_exercise_exercise_autoevaluation'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_exercise_autoevaluation', value: 1, name: 'type_exercise[config][exercise_autoevaluation][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.exercise_autoevaluation && _vm.ConfigTypeExercise.data.config.exercise_autoevaluation[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigAutoevaluation', undefined, false)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckConfigNotApply",attrs:{"datalabel":{text: _vm.$t('not_apply'), style: 'width: 300px', id: 'label_type_exercise_exercise_not_apply'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_exercise_not_apply', value: 1, name: 'type_exercise[config][not_apply][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.not_apply && _vm.ConfigTypeExercise.data.config.not_apply[0] === 1,
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigNotApply', undefined, false)}}})],1)])])]),_c('div',{key:_vm.refresh},[_c(_vm.include_component,{ref:"GhChildrenTypesBlock",tag:"component"})],1)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","padding-bottom":"10px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
            }},on:{"click":_vm.submitTypeExercise}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }