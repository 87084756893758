var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhDropSelector',{attrs:{"datablock":{
            id: 'drop_selector_section_users',
            title: _vm.$t('users'),
            second_title: _vm.$t('users'),
            num_results: !_vm.TabUsers.counters.require_billing ? _vm.TabUsers.counters.actual : _vm.TabUsers.counters.actual + ' ' + _vm.$t('of') + ' '  + _vm.TabUsers.counters.total,
            stateDrop: true
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('GhTableCommon',{attrs:{"extratable":{
                        id: 'users',
                    },"header":_vm.header,"data":_vm.TabUsers.records_data.length == 0 ? [] : _vm.TabUsers.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'user_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(_vm._s(itemProp[labelProp]))])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'user_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                    id: 'edit_user',
                                    text: _vm.$t('modify'),
                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                    item_prop: itemProp
                                }}})],1),_c('GhAction',{attrs:{"dataction":{
                                id: 'btn_delete_profile_'+itemProp['id'],
                                text: _vm.$t('delete'),
                                icon: require('../../assets/gh_new_dessign/trash.svg')
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','CompanyUser', itemProp.id,{'name': itemProp.firstname, 'surnames': itemProp.surname, 'email': itemProp.email}, _vm.$t('delete_user'), _vm.$t('preparing_delete_user'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{class:_vm.TabUsers.counters.require_billing && _vm.TabUsers.counters.actual == _vm.TabUsers.counters.total ? 'disabled' : '',attrs:{"to":{name: 'user'}}},[_c('GhButton',{attrs:{"datainput":{
                                        id: 'btn_add_user',
                                        text: _vm.$t('add'),
                                        class: _vm.TabUsers.counters.require_billing && _vm.TabUsers.counters.actual == _vm.TabUsers.counters.total ? 'disabled' : ''
                                    }}})],1)],1)]},proxy:true}],null,false,2300207685)})]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()]},proxy:true},(_vm.TabUsers.counters.require_billing)?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_business',
                    text: _vm.$t('more_users'),
                }},on:{"click":function($event){return _vm.openUsersShop()}}})]},proxy:true}:null],null,true)}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'users_shop_popup',
            title: _vm.$t('more_users'),
            type: 'add',
            style: 'width: 50%; min-width:920px;',
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important;'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"centered"},[_c('label',{staticClass:"T20"},[_vm._v(_vm._s(_vm.$t('add_more_users')))])]),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"element_block",staticStyle:{"min-width":"850px !important"}},[_c('div',{staticClass:"payment_box"},[_c('div',{staticClass:"centered"},[_c('div',{staticClass:"T14 header_payment"},[_c('div',{staticStyle:{"width":"70%"}}),_c('div',{staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t('total_pay'))+" ")])])]),_c('hr'),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"T14 header_payment"},[_c('div',{staticStyle:{"width":"70%","padding-top":"12px"}},[_c('div',{staticClass:"scrollbar",staticStyle:{"margin-right":"50px","max-height":"100px","height":"auto","overflow-y":"auto"}},[_c('div',{staticStyle:{"padding-top":"3px","padding-bottom":"5px"}},[_c('GhInputTextField',{attrs:{"datalabel":{
                                                    text: _vm.$t('number_desired_users') + '*',
                                                    style:'width: 250px; text-align: right;',
                                                    class: 'T15_b'
                                                },"datainput":{
                                                    required: true,
                                                    id: 'n_users_to_buy',
                                                    name: 'n_users_to_buy',
                                                    style:'width: 10%',
                                                    value: _vm.shop.n_users_to_buy,
                                                    type: 'int',
                                                    limitNumMin: 1
                                                }},on:{"keyup":_vm.calcUserPay}})],1)])]),_c('div',{staticStyle:{"width":"30%","padding-top":"12px"}},[_c('div',{staticClass:"scrollbar",staticStyle:{"max-height":"100px","height":"auto","overflow-y":"auto"}},[_c('div',{staticStyle:{"padding-top":"5px","padding-bottom":"5px","width":"100%","display":"flex"}},[_c('div',{staticClass:"container-element-info T15"},[_c('div',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t('user')))]),_c('div',{staticClass:"flex-align-right",staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(_vm.shop.n_users_to_buy + ' x ' + _vm.TabUsers.counters.price + ' €'))])])])]),_c('div',[_c('hr'),_c('div',{staticClass:"container-element-info"},[_c('div',[_vm._v(_vm._s(_vm.$t('total')))]),_c('div',{staticClass:"flex-align-right",staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(_vm.shop.total_price + ' €'))])])])])])])])])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_pay_users_shop_popup',
                    text: _vm.$t('make_payment')
                }},on:{"click":_vm.submitToBuy}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_users_shop_popup',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.closePopUp('users_shop_popup');}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }