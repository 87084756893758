var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100vh"}},[(_vm.haveData)?_c('GhCEQuestionnaireConstructor',{ref:"RefQuestionnaire",attrs:{"block":{
            svg: {
                noImg: require('@/assets/gh_new_dessign/no-image2.svg'),
                arrowRight: require('@/assets/questionnaire/arrow-right.svg'),
                arrowDown: require('@/assets/questionnaire/arrow-down.svg'),
                arrowUp: require('@/assets/questionnaire/arrow-up.svg'),
                close: '',
                levels: {
                    '1': require('@/assets/questionnaire/level1.svg'),
                    '2': require('@/assets/questionnaire/level2.svg'),
                    '3': require('@/assets/questionnaire/level3.svg'),
                    '4': require('@/assets/questionnaire/level4.svg'),
                    '5': require('@/assets/questionnaire/level5.svg'),
                },
                material: require('@/assets/questionnaire/book.svg'),
                download: require('@/assets/gh_new_dessign/descargar.svg')
            },
            pointer: {
                colors: [
                    '#C7C7C7',
                    '#F58449',
                    '#005392'
                ],
                title: [
                    'for_start',
                    'in_progress',
                    'finish'
                ]
            },
            evidence: {
                dispatch: 'easyUpload',
                accept: '.pdf, .xls, .xlsx, .doc, .docx, .odt',
                remove: true,
                removeText: '',
                removeSvg: require('@/assets/questionnaire/trash.svg')
            }
        },"dataIndex":_vm.getterQuestionnaireIndex,"dataBody":_vm.getterQuestionnaireBodyContent,"dataMaterials":_vm.getterQuestionnaireMaterial,"nextQuestionnary":_vm.getterNextQuestionnaire},on:{"setQuestion":_vm.handleSetQuestion,"getMaterial":_vm.handeGetMaterial,"setSave":_vm.handeSetSave,"setFinish":_vm.handeSetFinish,"watcherFinish":_vm.handleSetQuestion,"resetNextQuestionnaire":_vm.resetNextQuestion,"checkParent":_vm.handleCheckParent},scopedSlots:_vm._u([{key:"contentInsideMenu",fn:function(){return [(!_vm.getterQuestionnaireBodyContent.evaluator && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.finish !== undefined && _vm.getterQuestionnaireIndex.finish)?[_c('GhButton',{staticClass:"button-questionnaire-primary",attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.setFinishAllQuestionnaire()}}})]:_vm._e(),(_vm.$root._route.params.type !== 'grouper' && _vm.$root._route.params.type !== 'exercise' && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.joint_map !== undefined && _vm.getterQuestionnaireIndex.joint_map)?[_c('router-link',{attrs:{"to":{name: 'joint_map', params: {
                    id: _vm.$root._route.params.id,
                    type: _vm.$root._route.params.type
                }}}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                            id: 'btn_view_joint_map',
                            text: _vm.$t('view_joint_map')
                        }}})],1)]:_vm._e(),_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                    id: 'btn_view_global_map',
                    text: _vm.$t('view_global_map')
                },"disabled":_vm.$root._route.params.type === 'grouper' || _vm.$root._route.params.type === 'exercise'},on:{"click":function($event){return _vm.openMap()}}})]},proxy:true},{key:"extraContent",fn:function(){return [_c('GhChartTable',{ref:"RefGlobalMap",staticStyle:{"margin-top":"-15px"},attrs:{"extratable":{
                    id: 'global_map',
                    more: require('@/assets/questionnaire/arrow-down.svg'),
                    less: require('@/assets/questionnaire/arrow-up.svg'),
                    pointer: {
                        colors: [
                            '#C7C7C7',
                            '#F58449',
                            '#005392'
                        ],
                        title: [
                            'for_start',
                            'in_progress',
                            'finish'
                        ]
                    },
                },"header":_vm.headerChart,"data":_vm.getterQuestionnaireGlobalMap.data,"footer":[],"type":_vm.getterQuestionnaireGlobalMap.type,"evaluator":_vm.getterQuestionnaireGlobalMap.is_evaluator,"states":_vm.statesChart},on:{"advice":function($event){''}},scopedSlots:_vm._u([{key:"advice_actions",fn:function({itemProp}){return [((itemProp.hard_points !== undefined && itemProp.hard_points !== '') || (itemProp.improvement_opportunities !== undefined && itemProp.improvement_opportunities !== ''))?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px"},attrs:{"dataction":{
                                id: 'see_advice_' + itemProp.id,
                                text: _vm.$t('see'),
                                class: 'T-subtitle-colored',
                                icon: require('@/assets/gh_new_dessign/view2.svg'),
                            }},on:{"click":function($event){return _vm.showAdvice(itemProp)}}})]:_vm._e()]}},{key:"punctuation_actions",fn:function({itemProp}){return [(itemProp.detail)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px","margin-top":"1px"},attrs:{"dataction":{
                                id: 'detail_' + itemProp.id,
                                text: _vm.$t('detail'),
                                class: 'T-subtitle-colored',
                                icon: require('@/assets/gh_new_dessign/doc.svg'),
                            }},on:{"click":function($event){return _vm.showDetail(itemProp)}}})]:_vm._e()]}},{key:"header_actions",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"margin-left":"10px","margin-right":"10px","float":"right","margin-top":"10px","margin-bottom":"5px"}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                    id: 'btn_generate_pdf',
                                    text: _vm.pdfGenerate ? _vm.$t('generate_pdf') : _vm.$t('generating'),
                                    icon: _vm.pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                },"disabled":!_vm.pdfGenerate},on:{"click":function($event){return _vm.generatePDF()}}})],1)])]},proxy:true}],null,false,4015672369)})]},proxy:true},{key:"bodyHeaderActions",fn:function(){return [(!_vm.getterQuestionnaireBodyContent.evaluator)?[(_vm.getterQuestionnaireBodyContent.actions.know_more)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                            id: 'action_know_more',
                            text: _vm.$t('know_more'),
                            icon: require('@/assets/questionnaire/hat.svg')
                        }},on:{"click":function($event){return _vm.getKnowMore()}}})]:_vm._e(),(_vm.getterQuestionnaireBodyContent.actions.information)?[_c('div',{on:{"click":function($event){return _vm.getInformation()}}},[_c('GhCECustomTitle',{attrs:{"content":_vm.getterQuestionnaireInformation.information !== undefined ? _vm.getterQuestionnaireInformation.information : ''}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                    id: 'action_information',
                                    text: _vm.$t('information'),
                                    icon: require('@/assets/questionnaire/information.svg'),
                                }}})],1)],1)]:_vm._e()]:_vm._e()]},proxy:true},{key:"answerLegend",fn:function(){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row-reverse"},on:{"click":function($event){return _vm.getLegend()}}},[_c('GhCECustomTitle',{attrs:{"content":_vm.getterQuestionnaireInformation.legend !== undefined ? _vm.getterQuestionnaireInformation.legend : ''}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"padding-right":"5px","margin-right":"auto"},attrs:{"dataction":{
                            id: 'action_legend',
                            text: _vm.$t('legend'),
                            icon: require('@/assets/questionnaire/information.svg')
                        }}})],1)],1)]},proxy:true},{key:"buttonEvaluator",fn:function(){return [(_vm.getterQuestionnaireBodyContent !== undefined && _vm.getterQuestionnaireBodyContent.actions !== undefined && _vm.getterQuestionnaireBodyContent.actions.evaluate)?[_c('div',{staticClass:"no-apply-pallete"},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_evaluate',
                            text: _vm.$t('evaluate'),
                            class: 'T19 container-md_button_content button-questionnaire-primary',
                        }},on:{"click":function($event){return _vm.setEvaluate()}}})],1)]:_vm._e()]},proxy:true}],null,false,1279493147)}):_vm._e(),_c(_vm.popups.learning,{tag:"component",attrs:{"title":_vm.titlePopupLearning}}),_c(_vm.popups.evaluate,{tag:"component"}),_c(_vm.popups.advice,{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }