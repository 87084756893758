import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import { API_URL } from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);
export default{
    state: {
        index: [],
        refreshIndex: false,
        body: [],
        refreshBodyContent: false,
        information: [],
        refreshInformation: false,
        knowMoreOrLearning: [],
        refreshKnowMoreOrLearning: false,
        material: [],
        refreshMaterial: false,
        nextQuestionnaire: [],
        refreshNextQuestionnaire: false,
        jointMap: [],
        refreshQuestionnaireJointMap: false,
        globalMap: [],
        refreshQuestionnaireGlobalMap: false,
        evaluation: [],
        advice: [],
        refreshAdvice: false,
        refreshQuestionnaireEvaluation: false,
        emptyValorationLine: {
            show_improvement_opportunities: true,
            improvement_opportunities: '',
            show_hard_points: true,
            hard_points: '',
            description_hard_points: '',
            description_improvement_opportunities: ''
        },
    },
    mutations: {
        loadQuestionnaireIndex(state, payload){
            state.refreshIndex = !state.refreshIndex;
            state.index = payload;
        },
        loadQuestionnaireBodyContent(state, payload){
            state.refreshBodyContent = !state.refreshBodyContent;
            state.body = payload;
        },
        loadQuestionnaireInformation(state, payload){
            state.refreshInformation = !state.refreshInformation;
            state.information = payload;
        },
        loadQuestionnaireKnowMoreOrLearning(state, payload){
            state.refreshKnowMoreOrLearning = !state.refreshKnowMoreOrLearning;
            state.knowMoreOrLearning = payload;
        },
        loadQuestionnaireMaterial(state, payload){
            state.refreshMaterial = !state.refreshMaterial;
            state.material = payload;
        },
        loadNextQuestionnaire(state, payload){
            state.refreshNextQuestionnaire = !state.refreshNextQuestionnaire;
            state.nextQuestionnaire = payload;
        },
        loadQuestionnaireJointMap(state, payload){
            state.refreshQuestionnaireJointMap = !state.refreshQuestionnaireJointMap;
            state.jointMap = payload;
        },
        loadQuestionnaireJointMapSelection(state, payload){
            state.refreshQuestionnaireJointMap = !state.refreshQuestionnaireJointMap;
            state.jointMap.data[payload.key]['selecteds'] = payload.param;
            state.jointMap.data[payload.key]['hard_points'] = payload.param.hard_points.length;
            state.jointMap.data[payload.key]['improvement_opportunities'] = payload.param.improvement_opportunities.length;
        },
        loadQuestionnaireGlobalMap(state, payload){
            state.refreshQuestionnaireGlobalMap = !state.refreshQuestionnaireGlobalMap;
            state.globalMap = payload;
        },
        loadAdvice(state, payload){
            state.refreshAdvice = !state.refreshAdvice;
            state.advice = payload;
        },
        loadQuestionnaireEvaluation(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation = payload;
            this.commit('refreshValorations');
        },
        pushLineValoration(state){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation.valorations.push(state.emptyValorationLine);
            this.commit('refreshValorations');
        },
        removeLineValoration(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            delete state.evaluation.valorations[payload];
            state.evaluation.valorations = state.evaluation.valorations.filter(n => n);
            this.commit('refreshValorations');
        },
        updateLineValoration(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation.valorations[payload.key] = payload.params;
            this.commit('refreshValorations');
        },
        refreshValorations(state){
            if(state.evaluation.valorations !== undefined) {
                state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
                let improvement_opportunities = true;
                let hard_points = true;
                state.evaluation.valorations.forEach((item, key) => {
                    state.evaluation.valorations[key].show_improvement_opportunities = improvement_opportunities;
                    state.evaluation.valorations[key].show_hard_points = hard_points;

                    if (item.improvement_opportunities) {
                        improvement_opportunities = false;
                    }

                    if (item.hard_points) {
                        hard_points = false;
                    }
                });
            }
        }
    },
    getters: {
        getterQuestionnaireIndex(state){
            const dummy = state.refreshIndex; // eslint-disable-line no-unused-vars
            return state.index;
        },
        getterQuestionnaireBodyContent(state){
            const dummy = state.refreshBodyContent; // eslint-disable-line no-unused-vars
            return state.body;
        },
        getterQuestionnaireInformation(state){
            const dummy = state.refreshInformation; // eslint-disable-line no-unused-vars
            return state.information;
        },
        getterQuestionnaireKnowMoreOrLearning(state){
            const dummy = state.refreshKnowMore; // eslint-disable-line no-unused-vars
            return state.knowMoreOrLearning;
        },
        getterQuestionnaireMaterial(state){
            const dummy = state.refreshMaterial; // eslint-disable-line no-unused-vars
            return state.material;
        },
        getterNextQuestionnaire(state){
            const dummy = state.refreshNextQuestionnaire; // eslint-disable-line no-unused-vars
            return state.nextQuestionnaire;
        },
        getterQuestionnaireJointMap(state){
            const dummy = state.refreshQuestionnaireJointMap; // eslint-disable-line no-unused-vars
            return state.jointMap;
        },
        getterQuestionnaireGlobalMap(state){
            const dummy = state.refreshQuestionnaireGlobalMap; // eslint-disable-line no-unused-vars
            return state.globalMap;
        },
        getterQuestionnaireEvaluation(state){
            const dummy = state.refreshQuestionnaireEvaluation; // eslint-disable-line no-unused-vars
            return state.evaluation;
        },
        getterAdvice(state){
            const dummy = state.refreshAdvice; // eslint-disable-line no-unused-vars
            return state.advice;
        }
    },
    actions: {
        cleanQuestionnaireLoads(state){
            state.commit('loadQuestionnaireIndex', []);
            state.commit('loadQuestionnaireBodyContent', []);
            state.commit('loadQuestionnaireInformation', []);
            state.commit('loadQuestionnaireKnowMoreOrLearning', []);
            state.commit('loadQuestionnaireMaterial', []);
            state.commit('loadNextQuestionnaire', []);
            state.commit('loadQuestionnaireGlobalMap', []);
            state.commit('loadQuestionnaireJointMap', []);
        },
        async getQuestionnaireIndex(state, item){
            let requestUrl =  API_URL + item.type + "/index/" + item.id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireIndex', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireBody(state, item){
            let requestUrl =  API_URL + item.type + "/body/" + item.id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireBodyContent', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireFromIndex(state, item){
            let requestUrl =  API_URL + item.type + "/body/" + item.id + "/" + item.actual_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireBodyContent', response.data.data);

                        return response.data.data.id
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMaterialFromIndex(state, item){
            let requestUrl =  API_URL + item.type + "/materials/" + item.id + (item.actual_id !== undefined ? "/" + item.actual_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireMaterial', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMaterialBySlug(state, item){
            let requestUrl =  API_URL + item.type + "/materials/" + item.id + "/" + item.actual_id + "/" + item.slug;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireKnowMoreOrLearning', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireInformation(state, item){
            let requestUrl =  API_URL + item.type + "/information/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireInformation', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireLegend(state, item){
            let requestUrl =  API_URL + item.type + "/legend/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireInformation', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireKnowMore(state, item){
            let requestUrl =  API_URL + item.type + "/know-more/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireKnowMoreOrLearning', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStartEvaluation(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/" + item.id + "/" + item.actual_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireEvaluation', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEvaluationByUser(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/" + item.id + "/" + item.actual_id + "/" + item.user_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireEvaluation', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAdvice(state, item){
            let requestUrl =  API_URL + item.type + "/advice/" + item.id + "/" + item.actual_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadAdvice', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAdviceByUser(state, item){
            let requestUrl =  API_URL + item.type + "/advice/" + item.id + "/" + item.actual_id + "/" + item.user_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        response.data.data['user'] = item.full_name;
                        console.log(response.data.data);
                        state.commit('loadAdvice', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        setLineValoration(state){
            state.commit("pushLineValoration");
        },
        setRemoveLineValoration(state, item){
            state.commit("removeLineValoration", item.key);
        },
        updateContentValoration(state, item){
            state.commit("updateLineValoration", item);
        },
        async setStartEvaluation(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/" + item.id + "/" + item.actual_id + '/' + item.type_action;
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setQuestionnaireSave(state, item){
            let requestUrl =  API_URL + item.type + "/body/" + item.id + "/" + item.actual_id + "/save";
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        await state.dispatch('getQuestionnaireIndex', {
                            type: item.type,
                            id: item.id
                        }, {root: true});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setQuestionnaireFinish(state, item){
            let requestUrl =  API_URL + item.type + "/body/" + item.id + "/" + item.actual_id + "/finish";
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadNextQuestionnaire', response.data.data);

                        await state.dispatch('getQuestionnaireIndex', {
                            type: item.type,
                            id: item.id
                        }, {root: true});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async resetNextQuestionnaire(state){
            state.commit('loadNextQuestionnaire', []);
        },
        async changeSelectionMap(state, params){
            state.commit('loadQuestionnaireJointMapSelection', params);
        },
        async getUserColumns(state, item){
            let requestUrl =  API_URL + item.type + "/joint-map/columns/" + item.id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        return response.data.data
                        // state.commit('loadQuestionnaireJointMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireJointMap(state, item){
            let requestUrl =  API_URL + item.type + "/joint-map/" + item.id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireJointMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireGlobalMap(state, item){
            let requestUrl =  API_URL + item.type + "/global/" + item.id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireGlobalMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setFinishAllQuestionnaire(state, item){
            let requestUrl =  API_URL + "questionnaire/" + item.id + "/finish";
            let requestUrl2 =  API_URL + "company/finish_inicia";

            await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => { // eslint-disable-line no-unused-vars
                    if(item.type === 'initial'){
                        await axios({
                            method: "post",
                            url: requestUrl2,
                            headers: { "Content-Type" : "multipart/form-data"}
                        }).then(
                            async response2 => {}, // eslint-disable-line no-unused-vars
                            error => {
                                console.log(error);
                            }
                        )
                    }
                },
                error => {
                    console.log(error);
                }
            )

            router.push({name: 'result_quizz_initial',params:{id:item.id}});
        },
        async setElementSelected(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/answers/" + item.actual_id + "/" + item.id + "/" + item.parent + "/" + item.element + '/' + item.item;

            await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit('loadQuestionnaireEvaluation', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setResetEvaluation(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/reset/" + item.actual_id + "/" + item.id;

            return await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setValidateEvaluation(state, item){
            let requestUrl =  API_URL + item.type + "/evaluation/validate/" + item.id + "/" + item.actual_id;

            return await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPDFGlobalMap(state, item){
            let requestUrl =  API_URL + item.type + "/map-pdf/" + item.id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async setLiderMap(state, item){
            let request = API_URL + item.type + "/map/" + item.id + "/" + item.type_set;

            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            state.state.jointMap.data.forEach((i) => {
                i.selecteds.hard_points.forEach((hard_points) => {
                    formData.append('joint_evaluations[' + i.id + '][hard_points][]', hard_points);
                });

                i.selecteds.improvement_opportunities.forEach((improvement_opportunities) => {
                    formData.append('joint_evaluations[' + i.id + '][improvement_opportunities][]', improvement_opportunities);
                });
            });

            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        }
    }
};
