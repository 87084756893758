<template>
    <div class="float_window">
        <GhDropSelector
            :datablock="{
                id: 'drop_selector_section_users',
                title: $t('users'),
                second_title: $t('users'),
                num_results: !TabUsers.counters.require_billing ? TabUsers.counters.actual : TabUsers.counters.actual + ' ' + $t('of') + ' '  + TabUsers.counters.total,
                stateDrop: true
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <GhTableCommon
                        :extratable="{
                            id: 'users',
                        }"
                        :header="header"
                        :data="TabUsers.records_data.length == 0 ? [] : TabUsers.records_data"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <router-link :to="{name: 'user_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13" >{{itemProp[labelProp]}}</a></router-link>
                        </template>
                        <template v-slot:actions="{itemProp}">
                            <router-link :to="{name: 'user_id', params: {id: itemProp.id}}">
                                <GhAction
                                    :dataction="{
                                        id: 'edit_user',
                                        text: $t('modify'),
                                        icon: require('../../assets/gh_new_dessign/edit.svg'),
                                        item_prop: itemProp
                                    }"
                                />
                            </router-link>
                            <GhAction
                                :dataction="{
                                    id: 'btn_delete_profile_'+itemProp['id'],
                                    text: $t('delete'),
                                    icon: require('../../assets/gh_new_dessign/trash.svg')
                                }"
                                @click="Global.deleteConfirm('delete_confirm','CompanyUser', itemProp.id,{'name': itemProp.firstname, 'surnames': itemProp.surname, 'email': itemProp.email}, $t('delete_user'), $t('preparing_delete_user'), 'delete')"
                            />
                        </template>
                        <template v-slot:footer>
                            <div class="centered">
                                <router-link :to="{name: 'user'}" :class="TabUsers.counters.require_billing && TabUsers.counters.actual == TabUsers.counters.total ? 'disabled' : ''">
                                    <GhButton
                                        :datainput="{
                                            id: 'btn_add_user',
                                            text: $t('add'),
                                            class: TabUsers.counters.require_billing && TabUsers.counters.actual == TabUsers.counters.total ? 'disabled' : ''
                                        }"
                                    />
                                </router-link>
                            </div>
                        </template>
                    </GhTableCommon>
                </template>
                <template v-else-if="!haveData">
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
            <template v-slot:actions v-if="TabUsers.counters.require_billing">
                <GhAction
                    :dataction="{
                        id: 'modify_business',
                        text: $t('more_users'),
                    }"
                    @click="openUsersShop()"
                />
            </template>
        </GhDropSelector>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'users_shop_popup',
                title: $t('more_users'),
                type: 'add',
                style: 'width: 50%; min-width:920px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important;'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="centered">
                    <label class="T20">{{$t('add_more_users')}}</label>
                </div>
                <div class="centered">
                    <div class="element_block" style="min-width: 850px !important;">
                        <div class="payment_box">
                            <div class="centered">
                                <div class="T14 header_payment">
                                    <div style="width: 70%">

                                    </div>
                                    <div style="width: 30%">
                                        {{$t('total_pay')}}
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="centered">
                                <div class="T14 header_payment">
                                    <div style="width: 70%; padding-top: 12px;">
                                        <div class="scrollbar" style="margin-right: 50px; max-height: 100px; height: auto; overflow-y: auto">
                                            <div style="padding-top: 3px; padding-bottom: 5px;">
                                                <GhInputTextField
                                                    :datalabel="{
                                                        text: $t('number_desired_users') + '*',
                                                        style:'width: 250px; text-align: right;',
                                                        class: 'T15_b'
                                                    }"
                                                    :datainput="{
                                                        required: true,
                                                        id: 'n_users_to_buy',
                                                        name: 'n_users_to_buy',
                                                        style:'width: 10%',
                                                        value: shop.n_users_to_buy,
                                                        type: 'int',
                                                        limitNumMin: 1
                                                    }"
                                                    @keyup="calcUserPay"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 30%; padding-top: 12px;">
                                        <div class="scrollbar" style="max-height: 100px; height: auto; overflow-y: auto">
                                            <div style="padding-top: 5px; padding-bottom: 5px; width: 100%; display: flex">
                                                <div class="container-element-info T15">
                                                    <div style="width: 50%">{{$t('user')}}</div>
                                                    <div style="padding-right: 5px" class="flex-align-right">{{shop.n_users_to_buy + ' x ' + TabUsers.counters.price + ' €'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <hr>
                                            <div class="container-element-info">
                                                <div>{{$t('total')}}</div>
                                                <div class="flex-align-right" style="padding-right: 5px">{{shop.total_price + ' €'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_pay_users_shop_popup',
                        text: $t('make_payment')
                    }"
                    @click="submitToBuy"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_users_shop_popup',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp('users_shop_popup');"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from 'fe-gh-button-lib';
    import GhPopUp from 'fe-gh-popup-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_users",
        components: {
            GhDropSelector,
            GhAction,
            GhTableCommon,
            GhButton,
            GhPopUp,
            GhInputTextField
        },
        computed:{
            ...mapState(['TabUsers','Login']),
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'name', field: 'firstname', sorting: true, urlRedirect: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'surnames', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'email', field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                shop: {
                    n_users_to_buy: 1,
                    total_price: ''
                }
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getUserCounters', '', {root: true});
                await this.$store.dispatch('getUsers', '', {root: true});

                this.haveData = !this.haveData;
            }
        },
        methods: {
            openUsersShop(){
                this.Global.openPopUp('users_shop_popup');
                this.calcUserPay();
            },
            calcUserPay(){
                this.shop.n_users_to_buy = document.getElementById('n_users_to_buy').value;

                this.shop.total_price = this.shop.n_users_to_buy * parseFloat(this.TabUsers.counters.price);
                this.shop.total_price = this.shop.total_price.toFixed(2);
            },
            async submitToBuy(){
                await this.$store.dispatch('toBuyUsers', {items: this.shop}, {root:true});
            }
        }
    }
</script>

<style>
    .T20 {
        font-family: 'IBMPlexSans-Bold' !important;
        font-size: 16px !important;
        color: #0B4683 !important;
    }

    .element_block {
        margin: 20px 0px 20px 0px;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        overflow: hidden;
    }

    .payment_box {
        width: 97%;
        max-width: 100%;
        height: auto;
        max-height: 360px;
        padding: 5px 10px 15px 10px;
        background-color: #FFFFFF;
    }


    .container-element-info {
        display: flex;
        flex: 1 1 0px;
        justify-content: space-between;
    }

    .header_payment {
        width: 85%;
        display: flex;
        padding-bottom: 10px;
    }

    .scrollbar{
        scrollbar-color: #0b4683 rgba(0, 0, 0, 0.3) !important;
        scrollbar-width: thin !important;
    }

    .scrollbar::-webkit-scrollbar {
        width: 7px;
    }

    .scrollbar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: rgba(11,70,131,0.8);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
</style>